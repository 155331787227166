export default class FormTemplatesConstants {
    static readonly RoutePath = "/form/template";
    static readonly ResourceName = "/formtemplate";
    static readonly ActionCreate = "/create";
    static readonly RoutePathCreate = `${FormTemplatesConstants.RoutePath}${FormTemplatesConstants.ActionCreate}`;
    static readonly ContentType = {
        Text: "text",
        TextArea: "textArea",
        Number: "number",
        Select: "select",
        Header: "header",
        Uri: "uri",
        File: "file",
        cefaMultiFileCaption: "cefaMultiFileCaption",
        Date: "date",
        DateTime: "datetime",
        Time: "time",
        Table: "table",
        TableText: "text",
        TableNumber: "number",
        TableSelect: "select",
        TableDateTime: "datetime",
        Checkbox: "checkbox",
        UserSelector: "userSelector",
        TextTemplate: "textTemplate",
        Location: "location",
        What3Words: "what3words",
        System: "system",
    };
    static readonly Validations = {
        Array: "array",
        String: "string",
        Mixed: "mixed",
        Number: "number",
    };
    static readonly Conditions = {
        Operator: {
            Equals: "equals",
        },
        Logic: {
            And: "and",
            Or: "or",
        },
    };
}
