import { QueryKey } from "react-query";

export const assetNrDataKey = (id: string): string[] => ["asset-ces-data", id];

const assetCefaQueryKeys = {
    assetNrData: (id: string): QueryKey => [...assetNrDataKey(id)],
    assetNrDefects: (id: string): QueryKey => [
        ...assetNrDataKey(id),
        "defects",
    ],
};

export default assetCefaQueryKeys;
