import React from "react";
import { Tab } from "react-bootstrap";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import customComponentsService from "../../../customization/custom-components/customComponentsService";

const useAssetCustomTabs = (
    assetId: string,
    organisationId: string,
): {
    tabElements: JSX.Element[];
} => {
    const { canViewNrAssetDetails } = useViewPermissions(organisationId);

    const tabElements = customComponentsService.assetCustomDataTabs.map(
        (tabConfig, index) => {
            const { displayName, Component: AssetCustomDataComponent } =
                tabConfig;
            return assetId &&
                displayName &&
                canViewNrAssetDetails &&
                AssetCustomDataComponent ? (
                <Tab eventKey={`customTab-${index}`} title={displayName}>
                    <AssetCustomDataComponent assetId={assetId} />
                </Tab>
            ) : null;
        },
    );

    return {
        tabElements: tabElements,
    };
};

export default useAssetCustomTabs;
