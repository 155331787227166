import { consumerApi } from "../../../../customization/api/consumerRTKQueryApi";
import { wrapTagsWithQueryInvalidation } from "../../../../query/configureQuery";
import { Tags } from "../../../../store/features/dcpApi";
import { approvedExamsQueryKeysTranslator } from "../../exam-tools/approved-exams/queries/approvedExamsQueryKeysTranslator";
import {
    BatchAddExamsRequestData,
    BatchDeleteExamRequestData,
    BatchDetails,
    BatchExam,
    BatchGetRequestData,
    BatchRemoveExamRequestData,
    BatchRetryExamRequestData,
    BatchRow,
    BatchSubmitExamRequestData,
    BatchSummary,
    NrSubmissionStatus,
} from "../domain/batching";

export class BatchingApiRoutes {
    static readonly ApiRoute = "/batching";
    static readonly Exams = "/exams";
    static readonly RemoveExam = "/remove-exam";
    static readonly Submit = "/submit";
    static readonly Retry = "/retry";
    static readonly CreateRoute = "/create";
}

const apiSubPath = (organisationId: string) =>
    `/organisation/${organisationId}${BatchingApiRoutes.ApiRoute}`;

export interface UploadBatchingParams {
    missingCarrsExamFile: File;
    organisationId: string;
}

const { tagsToQueryKeys: tagsToProcessQueryKeys } =
    approvedExamsQueryKeysTranslator;

export const batchingApi = consumerApi.injectEndpoints({
    endpoints: (builder) => ({
        getBatchById: builder.query<BatchDetails, BatchGetRequestData>({
            query: (data) => `${apiSubPath(data.organisationId)}/${data.id}`,
            providesTags: (_result, _error, data) => [
                { type: Tags.Batch, id: data.id },
            ],
        }),
        getBatches: builder.query<BatchRow[], { organisationId: string }>({
            query: ({ organisationId }) => apiSubPath(organisationId),
            providesTags: () => [{ type: Tags.Batch }],
        }),
        batchAddExams: builder.mutation<string, BatchAddExamsRequestData>({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}${
                    BatchingApiRoutes.Exams
                }`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: (_result, _error, query) => [
                { type: Tags.BatchExams },
                { type: Tags.Batch },
                { type: Tags.Batch, id: query.id },
            ],
        }),
        batchRemoveExam: builder.mutation<string, BatchRemoveExamRequestData>({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}${
                    BatchingApiRoutes.RemoveExam
                }`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: (_result, _error, query) => [
                { type: Tags.Batch, id: query.id },
                { type: Tags.Batch },
                { type: Tags.BatchExams },
            ],
        }),
        submitBatch: builder.mutation<string, BatchSubmitExamRequestData>({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}${
                    BatchingApiRoutes.Submit
                }`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: () =>
                wrapTagsWithQueryInvalidation(
                    [{ type: Tags.Batch }],
                    tagsToProcessQueryKeys,
                ),
        }),
        retryBatch: builder.mutation<string, BatchRetryExamRequestData>({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}${
                    BatchingApiRoutes.Retry
                }`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: () => [
                { type: Tags.BatchNrStatus },
                { type: Tags.Batch },
                { type: Tags.BatchExams },
            ],
        }),
        deleteBatch: builder.mutation<string, BatchDeleteExamRequestData>({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}`,
                method: "DELETE",
                body: { ...data },
            }),
            invalidatesTags: () => [{ type: Tags.Batch }],
        }),
        createBatch: builder.mutation<string, string>({
            query: (organisationId) => ({
                url: `${apiSubPath(organisationId)}`,
                method: "POST",
            }),
            invalidatesTags: () => [{ type: Tags.Batch }],
        }),
        getBatchExams: builder.query<BatchExam[], string>({
            query: (organisationId) =>
                `${apiSubPath(organisationId)}/available-exams`,
            providesTags: () => [{ type: Tags.BatchExams }],
        }),
        getBatchExamsSummary: builder.query<
            Record<string, BatchSummary>,
            string
        >({
            query: (organisationId) => `${apiSubPath(organisationId)}/summary`,
        }),
        getSubmissionStatus: builder.query<
            NrSubmissionStatus,
            { organisationId: string; batchId: string }
        >({
            query: (queryArgs) =>
                `${apiSubPath(queryArgs.organisationId)}/nr/status?batchId=${
                    queryArgs.batchId
                }`,
            providesTags: () => [{ type: Tags.BatchNrStatus }],
        }),
    }),
});

export const {
    useGetBatchByIdQuery,
    useGetBatchesQuery,
    useBatchAddExamsMutation,
    useBatchRemoveExamMutation,
    useCreateBatchMutation,
    useDeleteBatchMutation,
    useSubmitBatchMutation,
    useRetryBatchMutation,
    useGetBatchExamsQuery,
    useGetBatchExamsSummaryQuery,
    useGetSubmissionStatusQuery,
} = batchingApi;
