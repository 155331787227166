import * as React from "react";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessConstants from "../../../Constants/ProcessConstants";
import {
    findNextAndPreviousId,
    sortNavigationItems,
} from "../../common/filters/helpers/processActivityNavigationHelper";
import { Process } from "../../process/domain/types";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar } from "react-bootstrap";
import { ProcessActivity } from "../domain/types";
import ExamPhotoOrderButton from "../../cefa/pdf-report-settings/components/ExamPhotoOrderButton";
import { useGetInfiniteProcessNavigatableItems } from "../query/processActivitiesQuery";

interface Props {
    process: Process;
    activity: ProcessActivity;
    orgShortName: string;
}

const getReturnPath = (
    processId: string,
    orgShortName: string,
    activityId: string,
): string => {
    if (!activityId) return "#";

    return `/${orgShortName}${OrganisationConstants.Process}/${processId}${ProcessConstants.Activity}/${activityId}`;
};

const ProcessActivityNavigationBar: React.FC<Props> = ({
    process,
    activity,
    orgShortName,
}) => {
    const { data } = useGetInfiniteProcessNavigatableItems(activity?.id, {});

    const listItems = React.useMemo(() => {
        if (!data) return [];
        return data?.pages?.map((page) => page.data).flat() || [];
    }, [data]);

    sortNavigationItems(listItems);

    const { previous, next } = findNextAndPreviousId(listItems, activity);

    const nextUrl = React.useMemo(
        () => getReturnPath(process.id, orgShortName, next),
        [process.id, orgShortName, next],
    );

    const prevUrl = React.useMemo(
        () => getReturnPath(process.id, orgShortName, previous),
        [process.id, orgShortName, previous],
    );

    return (
        <ButtonToolbar className="justify-content-end mb-2">
            <ExamPhotoOrderButton activity={activity} />
            <Link to={prevUrl} className="mr-2">
                <Button variant="secondary">Previous</Button>
            </Link>
            <Link to={nextUrl}>
                <Button variant="secondary">Next</Button>
            </Link>
        </ButtonToolbar>
    );
};

export default ProcessActivityNavigationBar;
