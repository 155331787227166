import moment from "moment";
import React from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import { BatchDetails } from "../../domain/batching";
import { useGetSubmissionStatusQuery } from "../../queries/BatchingApiSlice";

type Props = {
    batch: BatchDetails;
};

export const BatchSubmissionStates = {
    Delivered: 0,
    Rejected: 1,
    Pending: 2,
};

const NrSubmissionStatus: React.FC<Props> = ({ batch }) => {
    const { organisationId } = useOrganisationId();

    const { data, isLoading, isError } = useGetSubmissionStatusQuery({
        organisationId: organisationId,
        batchId: batch.id,
    });

    if (isLoading) return <Spinner animation={"border"}></Spinner>;

    if (!data || isError) return null;

    if (data.state === BatchSubmissionStates.Pending) {
        return (
            <Alert variant="info">
                <Alert.Heading>NR Upload Pending</Alert.Heading>
                Batch has been uploaded into NR. Awaiting Validation. Last
                Checked:{" "}
                {data.lastCheckedDate
                    ? moment(data.lastCheckedDate).format("DD/MM/YYYY")
                    : "Waiting for first check"}
            </Alert>
        );
    } else if (data.state === BatchSubmissionStates.Delivered) {
        return (
            <Alert variant="success">
                <Alert.Heading>NR Delivered</Alert.Heading>
                Batch has been successfully delivered
            </Alert>
        );
    } else if (data.state === BatchSubmissionStates.Rejected) {
        return (
            <Alert variant="danger">
                <Alert.Heading>NR Validation Failed</Alert.Heading>
                This batch failed to upload successfully into NR. The following
                details may help resolve the issue.
                <br />
                {data.details}
            </Alert>
        );
    }

    return null;
};

export default NrSubmissionStatus;
