import {
    crudPermissions,
    withFavoriteOperation,
    withMoveOperation,
} from "./crudPermissions";

const org = "organisation";
const formTemplate = "form-template";
const user = "user";
const process = "process";
const processTemplateRoles = "process-template/roles";
const report = "report";
const files = "files";
const userGroup = "user-group";

const PermissionConstants = {
    OrgFormTemplate: crudPermissions(org, formTemplate),
    OrgUser: crudPermissions(org, user),
    OrgProcess: crudPermissions(org, process),
    OrgMyAssigned: `${org}/${process}/my-assigned`,
    OrgReport: crudPermissions(org, report),
    OrgProcessTemplateRole: crudPermissions(org, processTemplateRoles),
    OrgSchedulerRead: `${org}/scheduler/read`,
    OrgFilesFolderTags: crudPermissions(org, files, "folder", "tags"),
    OrgFilesFileTags: crudPermissions(org, files, "file", "tags"),
    OrgFilesFolder: withFavoriteOperation(
        withMoveOperation(crudPermissions(org, files, "folder")),
    ),
    OrgFilesFile: withFavoriteOperation(
        withMoveOperation(crudPermissions(org, files, "file")),
    ),
    OrgFilesFileReplace: `${org}/${files}/file/replace`,
    OrgAsset: crudPermissions(org, "asset"),
    OrgFileSearch: `${org}/files/search`,
    OrgAssetTag: crudPermissions(org, "assettag"),
    OrgFilesFileComment: crudPermissions(org, "files", "file", "comments"),
    OrgGroupPermission: {
        manage: `${org}/${userGroup}/permissions/manage`,
    },
    OrgGroupUsers: {
        manage: `${org}/${userGroup}/users/manage`,
    },
    OrgUserGroup: crudPermissions(org, "user-group"),
    OrgHelpSection: crudPermissions(org, "help/section"),
    OrgWorkbank: crudPermissions(org, "workbank"),

    Examinations: {
        view: `${org}/examinations/view`,
        plan: `${org}/examinations/plan`,
        cancel: `${org}/examinations/cancel`,
    },
    ExaminationReviews: {
        view: `${org}/examinations/review/view`,
        assign: `${org}/examinations/review/assign`,
    },
    OrgExaminationsBulkUpdate: crudPermissions(org, "examinations", "batch"),
    OrgExaminationsMassRemoval: crudPermissions(
        org,
        "examinations",
        "mass-removal",
    ),

    CommercialReportDownloadPage: `${org}/report/download/commercial`,
    OrgCommercialMessUpdater: crudPermissions(org, "commercial", "massupdater"),

    ReportDownloadPage: `${org}/report/download/progress`,
    PerformanceDownloadPage: `${org}/report/download/performance`,
    ReactiveExamCreatorPage: `${org}/report/reactive-creator`,
    Batching: `${org}/batching`,
    MyWork: `cefa/${org}/my-work`,

    CesExamRequests: `${org}/ces/exam-requests/read`,
    NrAssetDetails: `${org}/ces/asset/read`,
    OrgExamManagement: `${org}/exam-management`,
    OrgExamManagementChangeExamType: `${org}/exam-management/change-exam-type`,
    OrgMissingCarrsExamIdTool: `${org}/missing-carrs-examid-tool`,
    OrgApprovedExamsReport: `${org}/approved-exams-report`,
    Apc: `cefa/${org}/apc`,
};

export default PermissionConstants;
