import FormTemplatesConstants from "../../../../Constants/FormTemplatesConstants";
import {
    TemplateContent,
    TemplateContentFile,
    TemplateContentTable,
    TemplateContentTableRow,
    TemplateContentTableRowValue,
    TemplateContentSelect,
} from "../../../../modules/template/domain/types";
import { DynamicFormValues } from "../types/dynamicFormTypes";

const FormValuesHelper = {
    Constants: {
        FileFoundString: "uploadedFileFound",
    },
    getInitialValuesFile: (
        contentItem: TemplateContent,
        output: Record<string, unknown>,
    ): void => {
        // file inputs can have the required validation applied and need a default string value supplied
        // so that when a file has already been uploaded the validator does not trigger
        // a selected file is stored as a file object not a string so we can filter on submission
        const contentItemFile = contentItem as TemplateContentFile;
        output[contentItem.field] = contentItemFile.files;
    },
    getInitialValuesTable: (
        contentItem: TemplateContent,
        output: Record<string, unknown>,
    ): void => {
        // Row values come as list of key/values need to compile into list of objects for formik
        output[contentItem.field] =
            (contentItem as TemplateContentTable).rows?.map(
                (a: TemplateContentTableRow) => {
                    const newObject: Record<string, unknown> = {};
                    a.values.forEach(
                        (propertyItem: TemplateContentTableRowValue) => {
                            newObject[propertyItem.field] =
                                propertyItem.value ?? "";
                        },
                    );
                    return newObject;
                },
            ) ?? [];
    },
    getInitialValuesSelect: (
        contentItem: TemplateContent,
        output: Record<string, unknown>,
    ): void => {
        output[contentItem.field] = (
            contentItem as TemplateContentSelect
        ).options.some((o) => o.value === contentItem.value)
            ? contentItem.value
            : "";
    },
    getInitialValuesGPS: (
        contentItem: TemplateContent,
        output: Record<string, unknown>,
    ): void => {
        const decRe = /[+-]?\d+(\.\d+)?/;
        const sepRe = /, ?/;
        const gpsRe = new RegExp(
            "^" + decRe.source + sepRe.source + decRe.source + "$",
        );
        if (!gpsRe.exec(contentItem.value)) {
            output[contentItem.field] = "";
            return;
        }

        const sepInd = contentItem.value.indexOf(", ");
        if (sepInd === -1) {
            output[contentItem.field] = contentItem.value;
        } else {
            // Remove space from separator
            output[contentItem.field] =
                contentItem.value.slice(0, sepInd + 1) +
                contentItem.value.slice(sepInd + 2);
        }
    },
    getInitialValues: (
        formContent: TemplateContent[],
    ): Record<string, unknown> => {
        const output: Record<string, unknown> = {};
        formContent.forEach((contentItem: TemplateContent) => {
            switch (contentItem.type) {
                case FormTemplatesConstants.ContentType.cefaMultiFileCaption:
                    FormValuesHelper.getInitialValuesFile(contentItem, output);
                    break;
                case FormTemplatesConstants.ContentType.File:
                    FormValuesHelper.getInitialValuesFile(contentItem, output);
                    break;
                case FormTemplatesConstants.ContentType.Table:
                    FormValuesHelper.getInitialValuesTable(contentItem, output);
                    break;
                case FormTemplatesConstants.ContentType.Header:
                case FormTemplatesConstants.ContentType.Uri:
                    // Ignore headers as we wont store a value
                    break;
                case FormTemplatesConstants.ContentType.Checkbox:
                    output[contentItem.field] = contentItem.value
                        ? contentItem.value
                        : "false";
                    break;
                case FormTemplatesConstants.ContentType.Select:
                    FormValuesHelper.getInitialValuesSelect(
                        contentItem,
                        output,
                    );
                    break;
                case FormTemplatesConstants.ContentType.Location:
                    FormValuesHelper.getInitialValuesGPS(contentItem, output);
                    break;
                default:
                    output[contentItem.field] = contentItem.value ?? "";
            }
        });
        return output;
    },
    convertValuesToHtmlFormData: (
        values: DynamicFormValues,
        contents: TemplateContent[],
        formData: FormData,
        hiddenFields: string[],
    ): void => {
        Object.entries(values)
            .filter((a) => {
                // remove tmp file string used for validation hack
                // Only include file data in the file object format
                const contentItem = contents.find((b) => b.field === a[0]);
                return !(
                    ["file", "photo"].includes(contentItem?.type ?? "") &&
                    typeof a[1] === "string"
                );
            })
            .filter((a) => {
                // Remove any values from hidden fields
                return !hiddenFields.includes(a[0]);
            })
            .forEach((property) => {
                const key = property[0];
                const value = property[1] as string | Blob;
                if (Array.isArray(value)) {
                    // concatenate parent key to sub object key
                    value.forEach((subValue, index) => {
                        Object.keys(subValue).forEach((subValueKey) => {
                            formData.append(
                                `${key}.${index}.${subValueKey}`,
                                subValue[subValueKey],
                            );
                        });
                    });
                } else {
                    formData.append(key, value);
                }
            });
    },
};

export default FormValuesHelper;
