import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import * as yup from "yup";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { CustomSelect, TextInput } from "../../../common/components/form";
import {
    SelectOptionItem,
    mapToSelectOptions,
} from "../../../common/types/reactSelect/SelectOptionItem";
import {
    ActivityActionParams,
    PostNotApplicableParams,
} from "../../../store/features/process-activity/types";
import {
    CustomActionButton,
    ProcessActivity,
    ProcessActivityType,
} from "../domain/types";
import { resolveActivityState } from "../helpers/activityStateHelpers";
import CustomActionButtonPrompt from "./CustomActionButtonPrompt";
import ConfirmBox from "../../../common/components/kendo-dialog-boxes/ConfirmBox";
interface ActionResult {
    isLoading: boolean;
    isError: boolean;
    error?: unknown;
}
export interface ActionBarActionProps {
    actionCallback: (arg: ActivityActionParams) => Promise<unknown> | void;
    actionResult: ActionResult;
}

export interface ActionBarNotApplicableActionProps {
    actionCallback: (arg0: PostNotApplicableParams) => Promise<unknown> | void;
    actionResult: ActionResult;
}

interface Props {
    activity: ProcessActivity;
    complete: ActionBarActionProps;
    notApplicable: ActionBarNotApplicableActionProps;
}

interface NotApplicableFormValues {
    comment: string;
    reason: SelectOptionItem;
}

interface CustomActionButtonState {
    isActive: boolean;
    customActionButton: CustomActionButton;
}

const ProcessActivityActionBar: React.FC<Props> = (props) => {
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    const { activity, complete, notApplicable } = props;
    const { isComplete, isNotApplicable, canChangeStatus } =
        resolveActivityState(activity);

    const [customActionButtonState, setCustomActionButtonState] =
        React.useState<CustomActionButtonState>({
            customActionButton: null,
            isActive: false,
        });

    const customActionButtonItems = React.useMemo(() => {
        if (!activity) return [];
        return (
            activity?.customActionButtons
                ?.map((customActionButton) => customActionButton)
                .flat() || []
        );
    }, [activity]);

    if (isComplete || isNotApplicable) return null;

    const confirmationRequired = activity.confirmation ?? false;
    const displayNotApplicable =
        activity.notApplicableFeature?.enabled ?? false;
    const displayComplete = activity.type !== ProcessActivityType.Approval;

    const clickNotApplicable = (formValues: NotApplicableFormValues): void => {
        notApplicable.actionCallback({
            activity,
            reason: formValues.reason?.value,
            comment: formValues.comment,
        });

        setShowDialog(false);
    };

    const initialFormValues: NotApplicableFormValues = {
        comment: "",
        reason: null,
    };
    const validation = yup.object({
        reason: activity.notApplicableFeature.reasons
            ? yup.object().nullable().required("Required")
            : null,
    });

    if (!canChangeStatus) return null;

    return (
        <>
            {customActionButtonState.isActive && (
                <CustomActionButtonPrompt
                    onClose={() =>
                        setCustomActionButtonState({
                            customActionButton: null,
                            isActive: false,
                        })
                    }
                    activityId={activity.id}
                    organisationId={activity.organisationId}
                    customActionButton={
                        customActionButtonState.customActionButton
                    }
                />
            )}

            <ButtonToolbar className="justify-content-end">
                {displayComplete && (
                    <div>
                        {!complete?.actionResult?.isLoading &&
                            customActionButtonItems.map(
                                (
                                    customActionButton: CustomActionButton,
                                    index: number,
                                ) => (
                                    <Button
                                        key={customActionButton.label.toString()}
                                        className="ml-1"
                                        variant={customActionButton.variant}
                                        onClick={() =>
                                            setCustomActionButtonState({
                                                customActionButton:
                                                    customActionButton,
                                                isActive: true,
                                            })
                                        }
                                    >
                                        {customActionButton.label}
                                    </Button>
                                ),
                            )}
                        <Button
                            className="ml-1"
                            variant="success"
                            onClick={() => {
                                confirmationRequired
                                    ? setShowConfirmation(true)
                                    : complete.actionCallback({ activity });
                            }}
                            disabled={complete.actionResult.isLoading}
                        >
                            Complete
                            {complete.actionResult.isLoading && (
                                <ButtonSpinner />
                            )}
                        </Button>
                        {showConfirmation && (
                            <ConfirmBox
                                title="Complete"
                                message="Are you sure you want to complete this activity?"
                                onConfirm={() => {
                                    complete.actionCallback({ activity });
                                    setShowConfirmation(false);
                                }}
                                onCancel={() => setShowConfirmation(false)}
                            />
                        )}
                    </div>
                )}
                {displayNotApplicable && (
                    <Button
                        className="ml-1"
                        variant="secondary"
                        onClick={() =>
                            confirmationRequired
                                ? setShowDialog(true)
                                : clickNotApplicable(initialFormValues)
                        }
                        disabled={notApplicable.actionResult.isLoading}
                    >
                        Not Applicable
                        {notApplicable.actionResult.isLoading && (
                            <ButtonSpinner />
                        )}
                    </Button>
                )}
            </ButtonToolbar>
            {showDialog && (
                <Dialog
                    title="Please confirm"
                    onClose={() => setShowDialog(false)}
                >
                    <div className="my-2">
                        <p>
                            Are you sure you want to mark this activity as not
                            applicable?
                        </p>
                        <p>
                            All sub activities will also be marked not
                            applicable.
                        </p>
                    </div>

                    <Formik.Formik
                        initialValues={initialFormValues}
                        validationSchema={validation}
                        onSubmit={clickNotApplicable}
                    >
                        {() => (
                            <Formik.Form>
                                {activity.notApplicableFeature.reasons && (
                                    <CustomSelect
                                        name="reason"
                                        label="Reason"
                                        placeholder="Select reason..."
                                        options={mapToSelectOptions(
                                            activity.notApplicableFeature
                                                .reasons,
                                            (i) => i,
                                            (i) => i,
                                        )}
                                    />
                                )}
                                <TextInput
                                    label="Comment (optional)"
                                    name="comment"
                                    placeholder="Write a comment..."
                                />
                                <DialogActionsBar layout="end">
                                    <Button
                                        variant="link"
                                        onClick={() => setShowDialog(false)}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Confirm
                                    </Button>
                                </DialogActionsBar>
                            </Formik.Form>
                        )}
                    </Formik.Formik>
                </Dialog>
            )}
        </>
    );
};

export default ProcessActivityActionBar;
