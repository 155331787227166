import {
    ProcessActivity,
    ProcessActivityNavigator,
} from "../../../process-activity/domain/types";

export const sortNavigationItems = (
    navigationItems: ProcessActivityNavigator[],
): void => {
    if (navigationItems?.every((x) => x.order === 0)) {
        // sorting on basis of Display Name
        navigationItems?.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base",
            }),
        );
    } else {
        // sorting on basis of Order property
        navigationItems?.sort((x) => x.order);
    }
};

export const findNextAndPreviousId = (
    navigationItems: ProcessActivityNavigator[],
    activity: ProcessActivity,
): { previous: string | null; next: string | null } => {
    const navigationItemIds = navigationItems.map((x) => x.id);
    const index = navigationItemIds.indexOf(activity.id);

    if (index === -1) {
        return { previous: null, next: null };
    }

    const length = navigationItemIds.length - 1;

    const previous =
        index > 0 ? navigationItemIds[index - 1] : navigationItemIds[length];

    const next =
        index < length ? navigationItemIds[index + 1] : navigationItemIds[0];

    return { previous, next };
};
